var network = angular.module('sc.network', [
  'sc'
]);
network.constant(
  '$vis', require('vis/dist/vis-network.min')
).run(function($vgisTools, $mdDialog) {
  $vgisTools.userNetwork = function(ev, user) {
    ev.preventDefault();
    ev.stopPropagation();
    $mdDialog.show({
      template: require('./index.html'),
      controller: require('./networkCtrl.js'),
      controllerAs: 'networkCtrl',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: true, // Only for -xs, -sm breakpoints.
      locals: {
        vgisUser: angular.copy(user)
      }
    });
  };
});

network.directive('vgisUserNetwork', require('./user'));
angular.module('sc').requires.push('sc.network');