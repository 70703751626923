module.exports = /*@ngInject*/ function($vis, $flash) {
  return {
    restrict: "E",
    scope: {
      vgisUser: '=',
      loading: '='
    },
    link: function(scope) {
      var userUid = 'User#'+scope.vgisUser.id;
      var nodes = new $vis.DataSet([
        { id: userUid,  value: 18,  group: 'prov',  label: scope.vgisUser.fullName, shape: 'icon', icon: { face: 'Material Icons', code: '\ue7fd', color: '#003553' } }
      ]);
      var edges = new $vis.DataSet([]);
      // var data = {
      //     nodes: nodes,
      //     edges: edges
      // };
      // var options = {
      //   nodes: {
      //     shape: 'dot',
      //     scaling:{
      //       label: {
      //         min:8,
      //         max:20
      //       }
      //     }
      //   }
      // };
      // var network = new $vis.Network(element[0], data, options);

      scope.vgisUser.$get(function(user) {
        user.ucis.forEach(function(uci) {
          var userUciUid = 'UserUci#'+uci.id;
          var isHealthy = uci.health && uci.health.status === 'HEALTHY';
          // var uciLabel = (uci.credentials && uci.credentials.username && uci.credentials.username !== uci.ucpUserId) ? uci.credentials.username+' ('+uci.ucpUserId+')' : uci.ucpUserId;
          nodes.add({ id: userUciUid,  value: 10,  group: 'prov',  label: uci.uciLabel, shape: 'icon', icon: { size: 32, face: 'Material Icons', code: isHealthy ? '\ue8c5' : '\ue61e', color: isHealthy ? '#008000' : '#ff0000' } });
          edges.add({ from: userUid, to: userUciUid });
          if (!isHealthy) {
            var healthUid = 'UserUciHealth#'+uci.id;
            var healthLabel = (uci.health && uci.health.message) ? uci.health.message : 'Missing UC health status';
            nodes.add({ id: healthUid,  value: 5,  group: 'uc',  label: healthLabel, shape: 'icon', icon: { size: 20, face: 'Material Icons', code: '\ue002', color: '#ff0000' } });
            edges.add({ from: healthUid, to: userUciUid });
          }

          function addUcpNode(uci) {
            var ucpUid = 'Ucp#'+uci.ucpId;
            if (!nodes.get(ucpUid)) {
              nodes.add({ id: ucpUid,  value: 16,  group: 'uc', label: uci.ucpLabel, shape: 'icon', icon: { face: 'Material Icons', code: '\ue30d', color: '#003553' } });
            }
            edges.add({ from: ucpUid, to: userUciUid, dashes: uci.ucpAuthLevel === 'INHERIT' });
          }
          if (uci.ucpId) { //desappeared from API :(
            addUcpNode(uci);
          } else {
            uci.$get(function(uci) {
              addUcpNode(uci);
            });
          }
          if (user.accountId != -1) {
            user.account.$get(function(account) {
              var accountUid = 'Account#'+account.id;
              var accountLabel = account.org ? account.name+' ('+account.org+')' : account.name;
              nodes.add({ id: accountUid,  value: 18,  group: 'prov',  label: accountLabel, shape: 'icon', icon: { face: 'Material Icons', code: '\ue0af', color: '#003553' } });
              edges.add({ from: userUid, to: accountUid });
              (account.ucis || []).forEach(function(uci) {
                var accountUciUid = 'AccountUci#'+uci.id;
                var isAccountUciHealthy = uci.health && uci.health.status === 'HEALTHY';
                // var aUciLabel = (uci.adminCredentails && uci.adminCredentails.username) ? uci.adminCredentails.username+' ('+uci.ucpAccountId+')' : uci.ucpAccountId;
                nodes.add({ id: accountUciUid,  value: 10,  group: 'prov', label: uci.ucpAccountId, shape: 'icon', icon: { size: 32, face: 'Material Icons', code: isAccountUciHealthy ? '\ue8c5' : '\ue61e', color: isAccountUciHealthy ? '#003553' : '#ff0000' } });
                edges.add({ from: accountUid, to: accountUciUid });
                if (uci.ucpId) {
                  var aUcpUid = 'Ucp#'+uci.ucpId;
                  if (!nodes.get(aUcpUid)) {
                    nodes.add({ id: aUcpUid,  value: 16,  group: 'uc', label: uci.ucpLabel, shape: 'icon', icon: { face: 'Material Icons', code: '\ue30d', color: '#003553' } });
                  }
                  edges.add({ from: aUcpUid, to: accountUciUid });
                }
                if (!isAccountUciHealthy) {
                  var aHealthUid = 'AccountUciHealth#'+uci.id;
                  var aHealthLabel = (uci.health && uci.health.message) ? uci.health.message : 'Missing UC health status';
                  nodes.add({ id: aHealthUid,  value: 5,  group: 'uc',  label: aHealthLabel, shape: 'icon', icon: { size: 20, face: 'Material Icons', code: '\ue002', color: '#ff0000' } });
                  edges.add({ from: aHealthUid, to: accountUciUid });
                }
              });
            });
            (user.integrations || []).forEach(function(integration) {
              var integrationUid = 'Integration#'+integration.code;
              nodes.add({ id: integrationUid, value: 10, label: integration.name, group: 'integ', shape: 'icon', icon: { size: 32, face: 'Material Icons', code: '\ue335', color: '#003553' } });
              edges.add({ from: userUid, to: integrationUid });
              var userIntegrationProfileUid = 'UserIntegrationProfile#'+integrationUid;
              angular.copy(integration).$profile(function(profile) {
                var profileLabel = profile.userName + '\nOrg: ' + profile.organizationId;
                nodes.add({ id: userIntegrationProfileUid,  value: 10,  group: 'integ',  label: profileLabel, shape: 'icon', icon: { size: 32, face: 'Material Icons', code: profile.healthy ? '\ue86c' : '\ue002', color: profile.healthy ? '#008000' : '#ff0000' } });
                edges.add({ from: integrationUid, to: userIntegrationProfileUid });
              }, function() {
                delete $flash.error;
                nodes.add({ id: userIntegrationProfileUid,  value: 8,  group: 'integ', label: '--no linked profile--', shape: 'icon', icon: { size: 32, face: 'Material Icons', code: '\ue002', color: '#DAA520' } });
                edges.add({ from: integrationUid, to: userIntegrationProfileUid });
              });
            });
          }
        });
      });
    }
  };
};